import React from 'react';
import './About.scss';
import Footer from '../footer/Footer';
import { FaGolfBall } from 'react-icons/fa';
import { MdOutlineGolfCourse } from 'react-icons/md';
import { GiGolfFlag } from 'react-icons/gi';
import { IoMdBeer } from 'react-icons/io'
import { useSpring, animated } from 'react-spring';

const About = () => {
  const aboutMeBg = useSpring({ from: { opacity: 0, transform: 'translateY(200px)' }, opacity: 1, transition: '1.5s ease-out', transform: 'translateY(0px)' });
  const aboutMe = useSpring({ from: { opacity: 0, transform: 'translateY(-250px)' }, opacity: 1, transition: '1.5s ease-out', transform: 'translateY(0px)' });

  return (
    <div className='About'>

      <animated.div style={aboutMeBg} className='aboutMe'>
      </animated.div>
      <animated.h1 style={aboutMe} className='aboutH1'>About Me</animated.h1>

      <div className='bottom'>
        <div className='myStory'>
          <h1>My Story</h1>
          <p>
            {`I'm Wes Griffin from Portland, Oregon. Creator of Misguided Golf.
            I'm a 24 handicap, all lip, no hole aficionado of the stix. I can hook, slice and chunk with the best of 'em. But all that is (hopefully) gonna change.
            So sit back, strap on... I mean strap in and follow the journey on YouTube! Now lets dip some holes.
            `}
          </p>
        </div>

        <div className='mySkills'>
          <div className='leftSkill'>
            <h1>My Skills</h1>
          </div>
          <div className='rightSkill'>
            <div>
              <FaGolfBall className='icon'/>
              <h3>Driving: sometimes right, sometimes left</h3>
            </div>
            <div>
              <GiGolfFlag className='icon'/>
              <h3>Chipping: if its not a chunk, its long</h3>
            </div>
            <div>
              <MdOutlineGolfCourse className='icon'/>
              <h3>Putting: crooked</h3>
            </div>
            <div>
              <IoMdBeer className='icon'/>
              <h3>Drinking: professional!</h3>
            </div>
          </div>
        </div>

        <div className='experience'>
          <div className='leftEx'>
            <h1>Current Handicap</h1>
          </div>
          <div className='rightEx'>
            <div>
              <h3>24... Like Griffey</h3>
            </div>
          </div>
        </div>

        <div className='footer'>
          <Footer className='foot' />
        </div>
      </div>
    </div>
  );
};

export default About;

// TODO: add in later
// <div className={styles.links}>
//   <div className={styles.linkLeft}>
//     <p>follow me</p>
//     <h1>LinkedIn</h1>
            
//     <Link 
//       className={styles.leftArrow}
//       to="route" 
//       onClick={(e) => 
//       {e.preventDefault(); window.open('https://www.linkedin.com/in/wesgriffincodes/');}
//       }>
//       <Arrow rotate='180deg' text={textLeft}/>
//     </Link>
//   </div>
//   <div className={styles.linkRight}>
//     <p>follow me</p>
//     <h1>Instagram</h1>
//     <Link 
//       to="route" 
//       onClick={(e) => 
//       {e.preventDefault(); window.open('https://www.instagram.com/codingclueless/');}
//       }>
//       <Arrow text={textRight}/>
//     </Link>   
//   </div>
// </div>;
