export const projects = [
  {
    title: 'YouTube',
    name: 'Misguided Golf',
    number: 1,
    paragraph: `I'm Wes Griffin and welcome to Misguided Golf! Take a look around, watch an episode and try not to shank.`,
    arrowLink: 'route',
    arrowOnClick: 'https://www.youtube.com/channel/UCjMhohCDrMZJyoUrAhyZbdQ',
    arrowText: 'WATCH ON YOUTUBE',
    id: 1
  },
  {
    title: 'random stuff',
    name: 'Social Media',
    number: 2,
    paragraph: 'Bored? Stop looking and start clickin. Come on... you know you want to!',
    arrowLink: 'route',
    arrowOnClick: 'https://www.instagram.com/misguidedgolf/',
    arrowText: 'INSTAGRAM',
    arrow2Link: 'route',
    arrow2OnClick: 'https://www.tiktok.com/@misguidedgolf',
    arrow2Text: 'TIKTOK',
    id: 2,
  },
  {
    title: 'soon to come',
    name: 'THE VAULT',
    number: 0,
    paragraph: `Give it time, We will release something soon.`,
    arrowLink: '/',
    arrowOnClick: '',
    arrowText: 'Doesnt work',
    id: 0,
  },
  {
    title: 'wes griffin',
    name: 'ABOUT ME',
    number: 3,
    paragraph: 'So you are curious who I am, huh? Well, then click the link below and get your fix...or not.',
    arrowLink: '/about',
    arrowOnClick: '',
    arrowText: 'MORE ABOUT WES',
    id: 3,
  },

];
