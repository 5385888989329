import React from 'react';
import './Footer.scss';
import { FaInstagram, FaYoutubeSquare, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <div className='Footer'>
      <p className='followMe'>FOLLOW ME</p>
      <div className='icons'>
        <Link 
          to="route" 
          onClick={(e) => 
          {e.preventDefault(); window.open('https://www.youtube.com/channel/UCjMhohCDrMZJyoUrAhyZbdQ/featured');}
          }>
          <FaYoutubeSquare className='icon'/>
        </Link>
        <Link 
          to="route" 
          onClick={(e) => 
          {e.preventDefault(); window.open('https://www.instagram.com/misguidedgolf/');}
          }>
          <FaInstagram className='icon'/>
        </Link>
        <Link 
          to="route" 
          onClick={(e) => 
          {e.preventDefault(); window.open('https://www.tiktok.com/@misguidedgolf');}
          }>
          <FaTiktok className='icon'/>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
